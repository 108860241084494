





















import {Prop} from "vue-property-decorator";
import {Component, Vue} from '@/shared/component';

@Component()
export default class Checked extends Vue {
	@Prop({ type: Boolean, required: true }) private value!: boolean;
	@Prop({ type: String , required: true }) private labelTrue!: string;
	@Prop({ type: String , required: true }) private labelFalse!: string;
	
}
