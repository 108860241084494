export class DateHelper {
	
	public static parseDisplayDate(str: string): Date {
		if (!str) return null;
		const [day, month, year] = str.split('/');
		return new Date(+year, +month - 1, +day);
	}
	
	public static toDisplayDate(date: Date): string {
		if (date instanceof Date) {
			let day: any = date.getDate();
			let month: any = (date.getMonth() + 1);
			if (day < 10) day = '0'+day;
			if (month < 10) month = '0'+month;
			return day+'/'+month+'/'+date.getFullYear();
		}
		return null;
	}
	
	public static toFormattedDate(date: Date): string {
		if (date instanceof Date) {
			let day: any = date.getDate();
			let month: any = (date.getMonth() + 1);
			if (day < 10) day = '0'+day;
			if (month < 10) month = '0'+month;
			return date.getFullYear()+'-'+month+'-'+day;
		}
		return null;
	}
	
	public static toLocalizedString(date: Date): string {
		let day: any = date.getDate();
		let month: any = (date.getMonth() + 1);
		let hour: any = date.getHours();
		let minute: any = date.getMinutes();
		let second: any = date.getSeconds();
		if (day    < 10) day    = '0'+day;
		if (month  < 10) month  = '0'+month;
		if (hour   < 10) hour   = '0'+hour;
		if (minute < 10) minute = '0'+minute;
		if (second < 10) second = '0'+second;
		return `${date.getFullYear()}-${month}-${day}T${hour}:${minute}:${second}+00:00`;
	}

	public static fromLocalizedString(dateStr: string): Date { // TODO Parse String sans GMT
		return new Date(dateStr);
	}
	
}