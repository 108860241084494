import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorFilterState,
	PaginatorActionWithFilters,
	PaginatorMutation,
	SetterMutation,
	StandardAction
} from '@/shared/utils';
import {UserService} from '@/xhttp';
import {User} from '@/models';

import storeToken from './token';
import {DateFilter} from '@/shared/filter';
import store from '@/stores';

Vue.use(Vuex);

export class UserFilter {
	
	email: string = '';
	firstName: string = '';
	lastName: string = '';
	phone: string = '';
	enable: boolean[] = []
	roles: string[] = [];
	createdAt: DateFilter = new DateFilter();
	
	public toString(): string {
		return JSON.stringify(this);
	}
}

class UserState extends PaginatorFilterState<UserFilter> {
	user: User = null;
	users: ResultType<User> = null;
	me: User = null;
	public constructor() {
		super(new UserFilter(), 'users', 'user');
	}
}

class UserStore {
	
	@XHTTPService(() => User)
	private userService: UserService;
	
	public state: UserState = new UserState();
	
	public mutations = {
		...PaginatorMutation(() => storeUser, 'users', 'user'),
		...SetterMutation(['me']),
	};
	
	public actions = {

		...StandardAction<User, UserState>(() => User, 'user'),
		...PaginatorActionWithFilters<User, UserState>(() => User, 'users', 'user'),
		
		async getMe(context: ActionContext<UserState, any>): Promise<User> {
			const me = await instance.userService.getMe();
			context.commit('setMe', me);
			if (me.currentToken) {
				storeToken.commit('setToken', me.currentToken);
			}
			return context.state.me;
		},

		patchMeResetPasswordRequest(context: ActionContext<UserState, any>, email: string): Promise<void> {
			return instance.userService.patchMeResetPasswordRequest(email);
		},
	};
	
}
const instance = new UserStore();
const storeUser = new Vuex.Store(instance);
store.registerModule('user', storeUser);
export default storeUser;
