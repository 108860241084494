import {DateHelper} from '@/shared/utils';

export class NumberFilter {
	public min: null = null;
	public max: null = null;
	
	public toJSON(): any {
		return {
			min: this.min,
			max: this.max,
		};
	}
}