import Vue from 'vue';
import Vuex, {ActionContext} from 'vuex';
import {Token, TokenLogin, User} from '@/models';
import {ResultType, XHTTPService} from '@/shared/xhttp';
import {
	PaginatorAction,
	PaginatorMutation,
	PaginatorState,
	StandardAction
} from '@/shared/utils';
import {TokenService} from '@/xhttp';
import caller, {CallerAjaxEvent} from '@/shared/caller';
import store from '@/stores';

import storeUser from './user';
import storeLoader from './loader';

Vue.use(Vuex);

let timeoutRefresh: any = null;

class TokenState extends PaginatorState {
	token: Token = null;
	edit: Token = null;
	tokens: ResultType<Token> = null;
	firstPageLoaded: boolean = false;
	public constructor() {
		super('tokens');
	}
}

class TokenStore {
	
	@XHTTPService(() => Token)
	private tokenService: TokenService;
	
	public state: TokenState = new TokenState();
	
	public mutations = {
		
		...PaginatorMutation(() => storeToken, 'tokens', 'edit'),
		
		firstPageLoaded(state: TokenState) {
			state.firstPageLoaded = true;
		},
		
		setToken(state: TokenState, token: Token) {
			state.token = token;
			if (timeoutRefresh) {
				clearTimeout(timeoutRefresh);
				timeoutRefresh = null;
			}
			if (token) {
				window.localStorage.setItem('api_token', token.id);
				
				///////////////////
				// refresh Token //
				///////////////////
				
				timeoutRefresh = setTimeout(() => {
					if (timeoutRefresh) {
						try {
							storeToken.dispatch('refresh');
						} catch(e) {
							this.setToken(state, null);
							(async () => {
								const router = (await import('@/router')).default;
								router.push({'name': 'login'});
							})();
						}
					}
				}, (token.lifetime + token.createTime - 120) * 1000 - (new Date).getTime()); // le reste de vie du token - 2 minute
				
			} else {
				window.localStorage.removeItem('api_token');
			}
		},
	};
	
	public actions = {

		...StandardAction<Token, TokenState>(() => Token, null, [ 'post', 'delete' ]),
		...PaginatorAction<Token, TokenState>(() => Token, 'tokens'),
		
		async login(context: ActionContext<TokenState, any>, tokenLogin: TokenLogin): Promise<Token> {
			const token = await instance.tokenService.login(tokenLogin);
			context.commit('setToken', token);
			if (token.user) {
				storeUser.commit('setMe', token.user);
			}
			return token;
		},
		
		async refresh(context: ActionContext<TokenState, any>, ): Promise<Token> {
			try {
				storeLoader.commit('disable');
				const token = await instance.tokenService.refresh();
				storeLoader.commit('enable');
				
				context.commit('setToken', token);
				if (token.user) {
					storeUser.commit('setMe', token.user);
				}
				return token;
			} catch (e) {
				storeLoader.dispatch('enable');
				throw e;
			}
		},
		
		clear(context: ActionContext<TokenState, any>, ) {
			context.commit('setToken', null);
			storeUser.commit('setMe', null);
		}
	};
	
	public constructor() {
		caller.addListener('ajax-send', (event: CallerAjaxEvent) => {
			const tokenId = window.localStorage.getItem('api_token');
			if (tokenId) {
				event.options.headers['Authorization'] = 'BEARER '+tokenId;
			}
		});
	}
	
}
const instance = new TokenStore();
const storeToken = new Vuex.Store(instance);
store.registerModule('token', storeToken);
export default storeToken;
