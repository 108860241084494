import {Watch} from 'vue-property-decorator';

export const WatchObjectProperties = function(path: string, ObjectClass: any): Function {
	
	const object = new ObjectClass();
	const callbackWatchs = Object.keys(object)
		.filter(name => typeof object[name] !== 'function')
		.map(name => Watch(path+'.'+name))
	;
	
	return (...args: any) => {
		for (const callbackWatch of callbackWatchs) {
			callbackWatch.apply(null, args);
		}
	};
};