





















import {Inject, Prop} from 'vue-property-decorator';
import {Component, Vue} from '@/shared/component';
import {NoCache} from '@/shared/decorator';
import {AscDesc} from '@/shared/xhttp';

@Component()
export default class PaginateTableHead<T> extends Vue {

	@Prop({ type: String }) private sortBy!: string;

	@Inject()
	private paginateTable: any;

	AscDesc = AscDesc;

	@NoCache()
	private get order(): string {
		return this.paginateTable.order;
	}

	@NoCache()
	private get direction(): string {
		return this.paginateTable.direction;
	}

	public onOrderBy(): void {
		if (!!this.sortBy) {
			if (this.paginateTable.order === this.sortBy) {
				this.paginateTable.direction = this.direction === AscDesc.DESC ? AscDesc.ASC : AscDesc.DESC;
				this.$emit('on-direction-by', this.sortBy);
			} else {
				this.paginateTable.order = this.sortBy;
				this.$emit('on-order-by', this.sortBy);
			}
		}
	}

}
