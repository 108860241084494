










import {Component, Vue} from '@/shared/component';

@Component({
	components: {
	}
})
export default class PaginateTableCell<T> extends Vue {
}
