


























import {Store} from 'vuex';
import Time from "gollumts-time/index";
import {Prop, Watch} from 'vue-property-decorator';
import {Component, Vue} from '@/shared/component';
import {AscDesc, ResultType} from '@/shared/xhttp';
import {PaginatorState} from '@/shared/utils';

import PaginateTable from '@/components/Paginate/Table.vue';

@Component({
	components: {
		PaginateTable,
	}
})
export default class CrudList<E> extends Vue {

	@Prop({ type: Store     , required: true       }) private store!: Store<PaginatorState>;
	@Prop({ type: Boolean   , default : true       }) private paginate;
	@Prop({ type: Boolean   , default : false      }) private dense;
	@Prop({ type: Function  , default: (() => '')  }) private cbBodyClass: Function;
	@Prop({ type: Boolean   , default: true        }) private hasFilter: boolean;
	@Prop({ type: Boolean   , default: true        }) private show: boolean;

	@Prop({ type: String    , default: () => 'getC'}) private methodName: string;
	@Prop({ type: String    , default: () => 'list'}) private listName: string;

	public get list(): ResultType<E> { return this.store.state[this.listName]; }

	public get order    (): string  { return this.store.state.order;     }
	public get direction(): AscDesc { return this.store.state.direction; }
	public get page     (): number  { return this.store.state.page;      }
	public get limit    (): number  { return this.store.state.limit;     }

	public set order    (value: string)  { this.store.commit('setOrder',     value); }
	public set direction(value: AscDesc) { this.store.commit('setDirection', value); }
	public set page     (value: number)  { this.store.commit('setPage',      value); }
	public set limit    (value: number)  { this.store.commit('setLimit',     value); }


	public async mounted(): Promise<void> {
		await this.refresh();
	}

	@Watch('show')
	private showWatcher(): void {
		this.refresh();
	}

	public async refresh(): Promise<void> {
		if (this.show) {
			await this.store.dispatch(this.methodName, this.hasFilter ? { filters: true } : {});
		}
	}

}
