export class EnumLiteral {

	public static values(EnumType: any): any[] {
		return Object.keys(EnumType)
			.map(key => EnumType[key])
		;
	}

	public static keys(EnumType: any): string[] {
		return Object.keys(EnumType);
	}

	public static key(EnumType: any, value: any): string {
		for (const key of Object.keys(EnumType)) {
			if (EnumType[key] === value) {
				return key;
			}
		}
		return null;
	}
}
