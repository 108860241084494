




















































import jQuery from 'jquery';
import {Prop, Provide, Ref} from "vue-property-decorator";
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';
import {AscDesc} from '@/shared/xhttp';

import PaginatePagination from '@/components/Paginate/Pagination.vue';

@Component({
	components: {
		PaginatePagination,
	}
})
export default class PaginateTable extends Vue {

	@Prop ({ type: Array   , default: null       }) private items!     : any[];
	@InOut({ type: String  , default: null       }) public  order!     : string;
	@InOut({ type: String  , default: null       }) public  direction! : AscDesc;
	@InOut({ type: Number  , default: null       }) private page!      : number;
	@InOut({ type: Number  , default: null       }) private limit!     : number;
	@Prop ({ type: Number  , default: null       }) private total!     : string;
	@Prop ({ type: Boolean , default: true       }) private paginate   : boolean;
	@Prop ({ type: Boolean , default: true       }) private sticky     : boolean;
	@Prop ({ type: Boolean , default: false      }) private dense      : boolean;
	@Prop ({ type: Function, default: (() => '') }) private cbBodyClass: Function;

	@Provide()
	private get paginateTable(): this {
		return this;
	}

	public async mounted(): Promise<void> {

		// Responsive

		this.eventResize = () => this.refreshResponsive();
		window.addEventListener('resize', this.eventResize);
		this.eventInterval = setInterval(() => this.refreshResponsive(), 150);

		this.refreshResponsive();
		await this.$nextTick();
		this.refreshResponsive(true);
	}

	public destroyed(): void {
		window.removeEventListener('resize', this.eventResize);
		clearInterval(this.eventInterval);
	}

	////////////////
	// Responsive //
	////////////////

	@Ref() private paginateTableData: HTMLElement;

	private eventResize: any = null;
	private eventInterval: any = null;
	private oldWidth: number = null;
	private domsModified: {
		subContainer: HTMLElement,
		originalContainer: HTMLElement,
		data: HTMLElement,
	}[] = [];
	private isResponsived = false;

	public updated(): void {
		this.refreshResponsive(true);
	}

	private openBody(e: Event): void {
		if (this.isResponsived) {
			const $tbody = jQuery(e.target).parents('tbody');
			$tbody.toggleClass('component-paginate-responsive-open')
		}
	}

	private hasScroll(): boolean {
		if (!this.paginateTableData) {
			return false;
		}
		return this.paginateTableData.scrollWidth > this.paginateTableData.clientWidth;
	}

	private refreshResponsive(force: boolean = false): void {
		const table = (<HTMLElement>this.$el).querySelector<HTMLElement>('.main-table');
		if (!table) {
			return;
		}

		const witdh = table.offsetWidth;

		// Rechercher des colonnes a cacher
		const thResponsiveNotWatched = this.$el.querySelectorAll('thead tr th[responsive]:not(.thead-tr-responsive)');
		if (thResponsiveNotWatched.length) {
			force = true;
			thResponsiveNotWatched.forEach(child => child.classList.add('thead-tr-responsive'));
		}
		const thWatchedNotResponsive = this.$el.querySelectorAll('thead tr th.thead-tr-responsive:not([responsive])');
		if (thWatchedNotResponsive.length) {
			force = true;
			thWatchedNotResponsive.forEach(child => child.classList.remove('thead-tr-responsive'));
		}

		if (force || this.oldWidth !== witdh) {
			this.oldWidth = witdh;

			this.clearResponsive();
			if (this.$el.querySelectorAll('th.component-paginate-tablehead').length && this.hasScroll()) {
				if (!this.isResponsived) {
					this.isResponsived = true;
				}
				this.applyResponsive();
			} else {
				if (this.isResponsived) {
					this.isResponsived = false;
				}
			}
		}

	}

	private clearResponsive() {
		if (this.paginateTableData) {
			this.paginateTableData.classList.remove('paginate-table-reponsived');
		}

		for (const domModified of this.domsModified) {
			domModified.originalContainer.appendChild(domModified.data);
			domModified.subContainer.parentElement.removeChild(domModified.subContainer);
		}
		this.domsModified = [];
		this.$el.querySelectorAll('.responsive-hidded').forEach(child => child.classList.remove('responsive-hidded'));
	}

	private applyResponsive() {

		const ths = this.$el.querySelectorAll<HTMLElement>('th.component-paginate-tablehead');

		const posResponsive = [];
		ths.forEach((th, i) => {
			if (th.classList.contains('thead-tr-responsive')) {
				posResponsive.push(i);
			}
		});
		posResponsive.reverse();

		for (const pos of posResponsive) {
			const childTh = this.$el.querySelector<HTMLElement>('th.component-paginate-tablehead:nth-child('+(pos+1)+')');
			const childTds = this.$el.querySelectorAll<HTMLElement>('td.component-paginate-tablecell:nth-child('+(pos+1)+')');

			childTh.classList.add('responsive-hidded');
			childTds.forEach(child => child.classList.add('responsive-hidded'));

			const label = childTh.querySelector<HTMLElement>('.paginate-tablehead-data').innerText;
			childTds.forEach((childTd) => {
				const subContainer = childTd.parentElement.nextElementSibling.querySelector<HTMLElement>('.paginate-table-sub-data-container');

				var subTr = document.createElement('tr');
				subTr.innerHTML = '<th>'+label+'</th><td class="paginate-sub-data-container"></td>';

				const data = childTd.querySelector<HTMLElement>('.paginate-tablecell-data');

				this.domsModified.push({
					data: data,
					originalContainer: data.parentElement,
					subContainer: subTr,
				});


				subTr.querySelector<HTMLElement>('.paginate-sub-data-container').appendChild(data);
				if (subContainer.childNodes.length) {
					subContainer.insertBefore(subTr, subContainer.childNodes[0]);
				} else {
					subContainer.appendChild(subTr);
				}
			});
			if (!this.hasScroll()) {
				break;
			}
		}

		if (this.paginateTableData && posResponsive.length) {
			this.paginateTableData.classList.add('paginate-table-reponsived');
		}
	}

}
