


















































import {Prop} from 'vue-property-decorator';
import {InOut} from 'vue-inout-prop-decorator';
import {Component, Vue} from '@/shared/component';

@Component()
export default class PaginatePagination extends Vue {
	@Prop ({ type: String , default : ''    }) private label!: string;
	@Prop ({ type: Number , required: true  }) private total!: string;
	@InOut({ type: Number , required: true  }) private page! : number;
	@InOut({ type: Number , required: true  }) private limit!: number;
	@Prop ({ type: Boolean, default : false }) private dense;
}
