export const Timeout = (ms: number = 800): any => {
	return (target: Object, propertyKey: string, descriptor: PropertyDescriptor) => {

		const oldCall = descriptor.value;
		const key = '__timeout_method_'+propertyKey+'__';
		descriptor.value = function (...args: any) {
			if (this[key]) {
				clearTimeout(this[key]);
			}
			this[key] = setTimeout(() => {
				this[key] = null;
				oldCall.apply(this, args);
			}, ms);
		};
	};
};
