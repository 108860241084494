
















import {Prop} from 'vue-property-decorator';
import {InOut} from "vue-inout-prop-decorator";
import {Component, Vue} from '@/shared/component';

@Component()
export default class TrueFalseMultiSelect extends Vue {
	
	@InOut({ type: Array  , isVModel: true    }) private value!     : boolean[];
	@Prop ({ type: String , required: true    }) private labelTrue! : string;
	@Prop ({ type: String , required: true    }) private labelFalse!: string;
	
}
