import {InOut} from 'vue-inout-prop-decorator';
import {Prop, Watch} from 'vue-property-decorator';
import {Stored} from 'vue-stored-prop-decorator';
import {Vue} from '@/shared/component';
import {Form} from '@/shared/form';
import {Store} from 'vuex';

import storeNotifier, {NotifyInterface} from '@/stores/modules/notifier';
import storeLoader from "@/stores/modules/loader";
import {Timeout} from '@/shared/decorator';
import {PaginatorState} from '@/shared/utils';

export abstract class AbstractEditVue<T extends any> extends Vue {

	@InOut({ type: Object, isVModel: true }) protected value!: T;
	@Prop({ type: Boolean, default: false}) noRefresh: boolean;

	@Stored(() => storeLoader) loading! : boolean

	protected form: Form = new Form();
	protected notFound: boolean = false;
	protected currentStore: Store<any>;

	private textEdit: string;
	private textCreate: string;
	private loadingId: number = null;
	private itemInner: any = null;

	public constructor(
		currentStore: Store<any>,
		textEdit: string,
		textCreate: string,
	) {
		super();
		this.currentStore = currentStore;
		this.textEdit = textEdit;
		this.textCreate = textCreate;
	}

	public get item(): T {
		return this.itemInner;
	}

	public set item(value: T) {
		this.itemInner = value;
		if (value && value.id) {
			this.currentStore.commit('setCurrent', value);
		}
	}

	public async mounted(): Promise<void> {
		this.item = null;
		await this.refresh();
	}

	@Watch('value')
	protected async refresh(): Promise<void> {
		console.log("Refresh");
		if (this.value) {
			if (this.value.id) {
				if (this.loadingId === this.value.id) {
					return;
				}
				if (!this.item || this.item.id !== this.value.id) {
					try {
						this.item = null;
						if (this.noRefresh) {
							this.item = this.value;
						} else {
							this.loadingId = this.value.id;
							await this.currentStore.dispatch('get', this.value.id);
							this.item = (this.currentStore.state as PaginatorState).current;
							this.loadingId = null;
						}
						this.$emit('load');
					} catch (e) {
						if (e.status === 404) {
							this.notFound = true;
						}
					}
				}
			} else {
				this.item = this.value;
			}
		}
		this.$emit('refresh', this.item);
	}

	protected async onSubmit(): Promise<void> {
		await this.form.call(async () => {
			//this.$emit('before-submit', this.value);
			const isNew: boolean = !this.value.id;
			if (this.item.id) {
				this.item = await this.currentStore.dispatch('put', this.item);
			} else {
				this.item = await this.currentStore.dispatch('post', this.item);
			}
			this.value = this.item;
			await this.saved();
			this.$emit('on-submit', this.value);
			
			this.notify(isNew ? this.textCreate : this.textEdit);
		});
	}
	
	protected async saved(): Promise<void> {
	}
}

